import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

const slice = createSlice({
  name: 'serviceTraffic',
  initialState: {
    servicesTraffic: {},
    aggregatedHits: {},
    lastHourTraffic: {},
    startTime: new Date().getTime() - 3600000,
    endTime: new Date().getTime(),
  },
  reducers: {
    trafficReceived: (serviceTraffic, action) => {
      const trafficLogs = action.payload[0].trafficLogs;
      Object.assign(serviceTraffic.servicesTraffic, trafficLogs);
      let allProviders = new Set();
      let maxTotalHits = 0;
      for (const [serviceUid, points] of Object.entries(trafficLogs)) {
        let hitsPerProvider = {};
        points.map((p) => {
          for (const [provider, metrics] of Object.entries(p.metrics)) {
            allProviders.add(provider);
            if (!hitsPerProvider[provider]) {
              hitsPerProvider[provider] = [];
            }
            hitsPerProvider[provider].push(metrics.hits);
          }
        });

        for (let i = points.length - 1; i >= 0; i--) {
          let p = points[i];
          let missingProvider = {};
          for (let provider of allProviders.values()) {
            if (!p.metrics[provider]) {
              p.metrics[provider] = {
                hits: 0,
                bytes: 0,
                cachedHitsPercentage: 0,
                cachedBytesPercentage: 0,
                errorsPercentage: 0,
                missingMetric: true,
              };
              missingProvider[provider] = true;
            }
          }
          let anyonePresent = false;
          for (let provider of allProviders.values()) {
            if (!missingProvider[provider]) {
              anyonePresent = true;
            }
          }
          if (anyonePresent) {
            break;
          }
        }

        let aggregatedHits = [];
        for (const [provider, hits] of Object.entries(hitsPerProvider)) {
          hits.map((h, i) => {
            if (!aggregatedHits[i]) {
              aggregatedHits[i] = 0;
            }
            aggregatedHits[i] += h;
          });
        }

        aggregatedHits.map((h) => {
          if (h > maxTotalHits) {
            maxTotalHits = h;
          }
        });

        serviceTraffic.aggregatedHits[serviceUid] = {
          points: aggregatedHits,
          maxTotalHits: maxTotalHits,
        };
      }

      let overallMax = 0;
      for (const [serviceUid, aggregated] of Object.entries(serviceTraffic.aggregatedHits)) {
        if (aggregated.maxTotalHits > overallMax) {
          overallMax = aggregated.maxTotalHits;
        }
      }

      for (const [serviceUid, aggregated] of Object.entries(serviceTraffic.aggregatedHits)) {
        aggregated.maxTotalHits = overallMax;
      }
    },

    lastHourReceived: (serviceTraffic, action) => {
      for (const [serviceUid, points] of Object.entries(action.payload[0].trafficLogs)) {
        let totalHits = 0;
        let totalBytes = 0;
        let totalErrors = 0;
        let totalHitsPerProvider = {};
        let count = 0;
        points.map((p) => {
          for (const [provider, metrics] of Object.entries(p.metrics)) {
            totalHits += metrics.hits * metrics.numMinutes;
            totalBytes += metrics.bytes * metrics.numMinutes;
            totalErrors += metrics.errorsPercentage;
            if (!totalHitsPerProvider[provider]) {
              totalHitsPerProvider[provider] = 0;
            }
            totalHitsPerProvider[provider] += metrics.hits * metrics.numMinutes;
            count++;
          }
        });

        if (count > 0) {
          totalErrors /= count;
          serviceTraffic.lastHourTraffic[serviceUid] = {
            totalHits: totalHits,
            totalBytes: totalBytes,
            errorsPercentage: Math.floor(totalErrors * 100) / 100,
            hitsPerProvider: totalHitsPerProvider,
          };
        }
      }
    },

    updateRange: (serviceTraffic, action) => {
      serviceTraffic.startTime = action.payload.startTime;
      serviceTraffic.endTime = action.payload.endTime;
    },

    onError: (serviceTraffic, action) => {
      let msg = action.payload[0];

      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      serviceTraffic.error = msg;
    },

    clear: (serviceTraffic, action) => {
      serviceTraffic.servicesTraffic = {};
      serviceTraffic.aggregatedHits = {};
      serviceTraffic.lastHourTraffic = {};
      serviceTraffic.startTime = new Date().getTime() - 3600000;
      serviceTraffic.endTime = new Date().getTime();
    },
  },
});

export const { trafficReceived, updateRange, lastHourReceived, onError, clear } = slice.actions;

export default slice.reducer;

// Action Creators

export const loadTraffic = (serviceUids, startTime, endTime) =>
  apiCallBegan({
    url: `/api/v1/traffic/overtime/${serviceUids}?startTime=${startTime}&endTime=${endTime}`,
    onSuccess: trafficReceived.type,
    onError: onError.type,
  });

export const loadLastHourTraffic = (serviceUids) =>
  apiCallBegan({
    url: `/api/v1/traffic/overtime/${serviceUids}?startTime=${
      new Date().getTime() - 3600000
    }&endTime=${new Date().getTime()}`,
    onSuccess: lastHourReceived.type,
    onError: onError.type,
  });
