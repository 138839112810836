import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { current } from '@reduxjs/toolkit';

interface AsyncTask {
  id: string;
  status: string;
  progress: number;
  message: string;
  details: string;
  created: Date;
  title: string;
}

interface AsyncTaskState {
  list: AsyncTask[];
  forceShow: boolean | null;
  lastFetch: Date | null;
  receivedOnce: boolean;
}

const initialState: AsyncTaskState = {
  list: [],
  forceShow: null,
  lastFetch: null,
  receivedOnce: false,
};

const slice = createSlice({
  name: 'asyncTasks',
  initialState,
  reducers: {
    asyncTasksReceived: (asyncTasks, action) => {
      let currentState = current(asyncTasks);

      if (
        currentState.list.length !== action.payload[0].length ||
        (currentState.list.length > 0 &&
          (action.payload[0][0].id !== currentState.list[0].id ||
            action.payload[0][0].status !== currentState.list[0].status ||
            action.payload[0][0].message !== currentState.list[0].message ||
            action.payload[0][0].details !== currentState.list[0].details))
      ) {
        if (currentState.receivedOnce) {
          asyncTasks.forceShow = true;
        }
      }
      console.log(asyncTasks.forceShow);
      asyncTasks.list = action.payload[0];
      asyncTasks.lastFetch = new Date();
      asyncTasks.receivedOnce = true;
    },

    setForceShow: (asyncTasks, action) => {
      if (action.payload !== null) {
        asyncTasks.forceShow = action.payload;
      } else {
        asyncTasks.forceShow = !asyncTasks.forceShow;
      }
    },
  },
});

export const { asyncTasksReceived, setForceShow } = slice.actions;

export default slice.reducer;

// Action Creators

export const loadAsyncTasks = () =>
  apiCallBegan({
    url: '/api/v1/async_tasks/',
    onSuccess: asyncTasksReceived.type,
  });
