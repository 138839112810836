import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

type CertificateType = 'MANAGED' | 'SELF_MANAGED' | 'EXTERNAL';
type CertificateStatus = 'VALID' | 'FAILED' | 'EXPIRED' | 'PENDING' | 'PENDING_EXTERNAL';

export interface Challenge {
  [key: string]: string;
}

interface Certificate {
  id: string;
  account: string;
  name: string;
  type: CertificateType;
  cn: string;
  not_valid_after: string;
  status: CertificateStatus;
  challenges: string;
}

interface CertificateState {
  list: Certificate[];
  loading: boolean;
  lastFetch: number | null;
  error: string | null;
  creating: boolean;
  creatingName: string;
  creatingPrivateKey: string;
  creatingCertificate: string;
  creatingCertificateChain: string;
  editingCertificateId: string;
  editingType: string;
  creatingIoriverCert: boolean;
  certificateRequestSent: boolean;
  certificateCreated: boolean;
  createdCertificateId: string | null;
  editing?: boolean;
}

const initialState: CertificateState = {
  list: [],
  loading: false,
  lastFetch: null,
  error: null,
  creating: false,
  creatingName: '',
  creatingPrivateKey: '',
  creatingCertificate: '',
  creatingCertificateChain: '',
  editingCertificateId: '',
  editingType: '',
  creatingIoriverCert: false,
  certificateRequestSent: false,
  certificateCreated: false,
  createdCertificateId: null,
};

const slice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    // actions => action handlers

    certificateCreationStart: (certificates, action) => {
      certificates.creating = true;
    },

    certificateCreationStop: (certificates, action) => {
      certificates.creating = false;
      certificates.creatingName = '';
      certificates.creatingPrivateKey = '';
      certificates.creatingCertificate = '';
      certificates.creatingCertificateChain = '';
      certificates.error = '';
    },

    certificateEditingStart: (certificates, action) => {
      certificates.editing = true;
      certificates.editingCertificateId = action.payload.id;
      certificates.creatingName = action.payload.name;
      certificates.editingType = action.payload.type;
      window.location.hash = certificates.editingCertificateId;
    },

    certificateEditingStop: (certificates, action) => {
      certificates.editing = false;
      certificates.creatingName = '';
      certificates.creatingPrivateKey = '';
      certificates.creatingCertificate = '';
      certificates.creatingCertificateChain = '';
      window.location.hash = '';
    },

    setCertificateName: (certificate, action) => {
      certificate.creatingName = action.payload;
    },

    setPrivateKey: (certificate, action) => {
      certificate.creatingPrivateKey = action.payload;
    },

    setCertificate: (certificate, action) => {
      certificate.creatingCertificate = action.payload;
    },

    setCertificateChain: (certificate, action) => {
      certificate.creatingCertificateChain = action.payload;
    },

    certificatesReceived: (certificates, action) => {
      certificates.list = action.payload[0];
      certificates.loading = false;
      certificates.lastFetch = Date.now();
    },

    certificatesAdded: (certificates, action) => {
      certificates.list.push(action.payload[0]);
      certificates.creating = false;
      certificates.creatingName = '';
      certificates.creatingPrivateKey = '';
      certificates.creatingCertificate = '';
      certificates.creatingCertificateChain = '';
      certificates.createdCertificateId = action.payload[0].id;
    },

    certificatesUpdated: (certificates, action) => {
      certificates.list = certificates.list.map((content) =>
        content.id === action.payload.id ? action.payload : content,
      );
      certificates.editing = false;
    },

    certificatesDeleted: (certificates, action) => {
      const deleted = action.payload[1].payload.id;
      certificates.list = certificates.list.filter((s) => s.id !== deleted);
    },

    certificateError: (certificates, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      certificates.error = msg;
      certificates.certificateRequestSent = false;
    },

    filesParsed: (certificates, action) => {
      let resp = action.payload[0];
      if (resp['key']) {
        certificates.creatingPrivateKey = action.payload[0]['key'];
      } else if (resp['ca']) {
        certificates.creatingCertificateChain = action.payload[0]['ca'];
      } else if (resp['certificate']) {
        certificates.creatingCertificate = action.payload[0]['certificate'];
      }
    },

    ioriverCertificateCreationStarted: (certificates, action) => {
      certificates.creatingIoriverCert = true;
      certificates.error = null;
    },

    ioriverCertificateCreationStopped: (certificates, action) => {
      certificates.creatingIoriverCert = false;
      certificates.certificateCreated = false;
      certificates.createdCertificateId = null;
    },

    certificateRequestSent: (certificates, action) => {
      certificates.certificateRequestSent = true;
    },

    certificateBackgroundTaskFinished: (certificates, action) => {
      certificates.certificateRequestSent = false;
      if (action.payload[0]) {
        certificates.certificateCreated = true;
      } else {
        certificates.error = action.payload[1];
      }
    },
  },
});

export const {
  certificatesReceived,
  certificatesAdded,
  certificatesUpdated,
  certificatesDeleted,
  certificateError,
  certificateCreationStart,
  certificateCreationStop,
  certificateEditingStart,
  certificateEditingStop,
  setCertificateName,
  setPrivateKey,
  setCertificate,
  setCertificateChain,
  filesParsed,
  ioriverCertificateCreationStarted,
  ioriverCertificateCreationStopped,
  certificateBackgroundTaskFinished,
  certificateRequestSent,
} = slice.actions;
export default slice.reducer;

// Action Creators

export const loadCertificates = () =>
  apiCallBegan({
    url: '/api/v1/certificates/',
    onSuccess: certificatesReceived.type,
  });

export const addCertificate = (c: Certificate) =>
  apiCallBegan({
    url: `/api/v1/certificates/`,
    method: 'post',
    data: c,
    onSuccess: certificatesAdded.type,
    onError: certificateError.type,
    onBackgroundTaskFinished: certificateBackgroundTaskFinished.type,
  });

export const updateCertificate = (id: string, c: Certificate) =>
  apiCallBegan({
    url: `/api/v1/certificates/${id}/`,
    method: 'put',
    data: c,
    onSuccess: certificatesUpdated.type,
    onError: certificateError.type,
  });

export const deleteCertificate = (c: string) =>
  apiCallBegan({
    id: c,
    url: `/api/v1/certificates/${c}/`,
    method: 'delete',
    onSuccess: certificatesDeleted.type,
  });

export const parseFile = (fileContent: object) => {
  return apiCallBegan({
    url: `/api/v1/certificates/parse_file/`,
    method: 'post',
    data: fileContent,
    onSuccess: filesParsed.type,
    onError: certificateError.type,
  });
};
