import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { toast } from 'react-toastify';

export type Severity = 'CRITICAL' | 'HIGH' | 'MEDIUM' | 'LOW';

interface SupportTicket {
  severity: Severity;
  title: string;
  description: string;
}

interface SupportTicketState {
  submitting: boolean;
  submitted: boolean;
}

const initialState: SupportTicketState = {
  submitting: false,
  submitted: false,
};

const slice = createSlice({
  name: 'supportTicket',
  initialState,
  reducers: {
    reset(state, action) {
      state.submitted = false;
      state.submitting = false;
    },

    onStart(state, action) {
      state.submitted = false;
      state.submitting = true;
    },

    ticketSubmitted: (state, action) => {
      state.submitted = true;
      state.submitting = false;
    },

    onError: (state, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        if (Array.isArray(msg)) {
          msg = msg.join(',');
        }
      }

      toast.error(msg);
      state.submitted = false;
      state.submitting = false;
    },
  },
});

export const { onStart, onError, ticketSubmitted, reset } = slice.actions;

export default slice.reducer;

// Action Creators

export const submitSupportTicket = (data: SupportTicket) =>
  apiCallBegan({
    url: '/api/v1/support-ticket/',
    method: 'post',
    data: data,
    onStart: onStart.type,
    onSuccess: ticketSubmitted.type,
    onError: onError.type,
  });
