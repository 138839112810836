import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';

type BehaviorActionType =
  | 'SET_RESPONSE_HEADER'
  | 'CACHE_TTL'
  | 'REDIRECT_HTTP_TO_HTTPS'
  | 'CACHE_BEHAVIOR'
  | 'BROWSER_CACHE_TTL'
  | 'REDIRECT'
  | 'ORIGIN_CACHE_CONTROL'
  | 'BYPASS_CACHE_ON_COOKIE'
  | 'CACHE_KEY'
  | 'AUTO_MINIFY'
  | 'HOST_HEADER_OVERRIDE'
  | 'SET_CORS_HEADER'
  | 'OVERRIDE_ORIGIN'
  | 'ORIGIN_ERRORS_PASS_THRU'
  | 'FOLLOW_REDIRECTS'
  | 'STATUS_CODE_CACHE'
  | 'GENERATE_PREFLIGHT_RESPONSE'
  | 'STATUS_CODE_BROWSER_CACHE'
  | 'STALE_TTL'
  | 'STREAM_LOGS'
  | 'ALLOWED_METHODS'
  | 'COMPRESSION'
  | 'GENERATE_RESPONSE'
  | 'CACHED_METHODS'
  | 'SET_REQUEST_HEADER'
  | 'VIEWER_PROTOCOL'
  | 'DELETE_REQUEST_HEADER'
  | 'DELETE_RESPONSE_HEADER'
  | 'LARGE_FILES_OPTIMIZATION';

type CacheBehaviorValueType = 'CACHE' | 'BYPASS';

export interface BehaviorAction {
  id: string;
  type: BehaviorActionType;
  max_ttl: string | null;
  response_header_name: string | null;
  response_header_value: string | null;
  cache_behavior_value: CacheBehaviorValueType;
  redirect_url: string | null;
  origin_cache_control_enabled: boolean | null;
  pattern: string | null;
  cookie: string | null;
  auto_minify: string | null;
  host_header: string | null;
  use_domain_origin: boolean | null;
  origin: string | null;
  enabled: boolean | null;
  cache_key: string | null;
  cors_allow_origin_domain: boolean | null;
  status_code: number | null;
  unified_log_destination: string | null;
  unified_log_sampling_rate: number | null;
  allowed_methods: string | null;
  response_page_path: string | null;
  cached_methods: string | null;
  genId?: number;
  validationError?: string | null;
  request_header_name?: string | null;
  request_header_value?: string | null;
  generate_preflight_allowed_headers?: string | null;
  viewer_protocol: 'HTTP_AND_HTTPS' | 'HTTPS_ONLY' | 'REDIRECT_HTTP_TO_HTTPS';
}

type BehaviorActionLabelsMap = {
  [key in BehaviorActionType]: string;
};

export const BehaviorActionTypes: BehaviorActionLabelsMap = {
  CACHE_BEHAVIOR: 'Cache Behavior',
  CACHE_TTL: 'Cache TTL',
  REDIRECT_HTTP_TO_HTTPS: 'Redirect HTTP to HTTPS',
  SET_RESPONSE_HEADER: 'Set Response Header',
  BROWSER_CACHE_TTL: 'Browser Cache TTL',
  REDIRECT: 'Redirect to URL',
  ORIGIN_CACHE_CONTROL: 'Origin Cache Control',
  BYPASS_CACHE_ON_COOKIE: 'Bypass Cache On Cookie',
  CACHE_KEY: 'Cache Key',
  AUTO_MINIFY: 'Auto Minify',
  HOST_HEADER_OVERRIDE: 'Host Header Override',
  SET_CORS_HEADER: 'Set CORS Header',
  OVERRIDE_ORIGIN: 'Origin Override',
  ORIGIN_ERRORS_PASS_THRU: 'Origin Errors Pass-Thru',
  FOLLOW_REDIRECTS: 'Follow Redirects',
  STATUS_CODE_CACHE: 'Status Code Cache',
  GENERATE_PREFLIGHT_RESPONSE: 'Generate Preflight Response',
  STATUS_CODE_BROWSER_CACHE: 'Status Code Browser Cache',
  STALE_TTL: 'Stale TTL',
  STREAM_LOGS: 'Stream Logs',
  ALLOWED_METHODS: 'Allowed Methods',
  COMPRESSION: 'Compression',
  GENERATE_RESPONSE: 'Generate Response',
  CACHED_METHODS: 'Cached Methods',
  SET_REQUEST_HEADER: 'Set Request Header',
  VIEWER_PROTOCOL: 'Viewer Protocol',
  DELETE_REQUEST_HEADER: 'Delete Request Header',
  DELETE_RESPONSE_HEADER: 'Delete Response Header',
  LARGE_FILES_OPTIMIZATION: 'Large Files Optimization',
};

export function getBehaviorActionValue(a: BehaviorAction): string {
  switch (a.type) {
    case 'ALLOWED_METHODS':
      return <string>a.allowed_methods;
    case 'AUTO_MINIFY':
      return <string>a.auto_minify;
    case 'BROWSER_CACHE_TTL':
      return a.max_ttl + '';
    case 'BYPASS_CACHE_ON_COOKIE':
      return 'Enabled';
    case 'CACHE_BEHAVIOR':
      return a.cache_behavior_value === 'CACHE' ? 'Cache' : 'Bypass';
    case 'CACHE_KEY':
      return '';
    case 'CACHE_TTL':
      return a.max_ttl + '';
    case 'CACHED_METHODS':
      return a.cached_methods + '';
    case 'COMPRESSION':
      return a.enabled ? 'Enabled' : 'Disabled';
    case 'DELETE_REQUEST_HEADER':
      return a.request_header_name + '';
    case 'DELETE_RESPONSE_HEADER':
      return a.response_header_name + '';
    case 'FOLLOW_REDIRECTS':
      return 'Enabled';
    case 'GENERATE_PREFLIGHT_RESPONSE':
      return (
        'Allowed Methods: ' +
        a.response_header_value +
        ' Max-Age:' +
        a.max_ttl +
        ' Allowed Headers: ' +
        a.generate_preflight_allowed_headers
      );
    case 'GENERATE_RESPONSE':
      return 'Response page: ' + a.response_page_path;
    case 'HOST_HEADER_OVERRIDE':
      return 'Host header: ' + (a.use_domain_origin ? 'Origin domain' : a.host_header);
    case 'LARGE_FILES_OPTIMIZATION':
      return 'Enabled';
    case 'ORIGIN_CACHE_CONTROL':
      return a.origin_cache_control_enabled ? 'Enabled' : 'Disabled';
    case 'REDIRECT':
      return 'To: ' + a.redirect_url;
    case 'SET_CORS_HEADER':
      return a.response_header_name + ': ' + a.response_header_value;
    case 'SET_REQUEST_HEADER':
      return a.request_header_name + ': ' + a.request_header_value;
    case 'SET_RESPONSE_HEADER':
      return a.response_header_name + ': ' + a.response_header_value;
    case 'STALE_TTL':
      return 'Equals:' + a.max_ttl;
    case 'STATUS_CODE_BROWSER_CACHE':
      return a.status_code + ' TTL: ' + a.max_ttl;
    case 'STATUS_CODE_CACHE':
      return a.cache_behavior_value === 'CACHE' ? a.status_code + ' TTL: ' + a.max_ttl : 'Bypass';
    case 'STREAM_LOGS':
      return 'Sampling rate: ' + a.unified_log_sampling_rate + '%';
    case 'VIEWER_PROTOCOL':
      return a.viewer_protocol;
  }
  return '';
}

export function validateActions(behavior_actions: BehaviorAction[]) {
  let invalid = false;
  behavior_actions.map((a) => {
    if (a.type === 'BROWSER_CACHE_TTL' || a.type === 'CACHE_TTL') {
      if (!a.max_ttl) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      }
      if (!parseInt(a.max_ttl)) {
        a.validationError = 'TTL should be a valid number';
        invalid = true;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'SET_RESPONSE_HEADER' || a.type === 'SET_CORS_HEADER') {
      if (!a.response_header_name || (!a.response_header_value && !a.cors_allow_origin_domain)) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'CACHE_BEHAVIOR') {
      if (!a.cache_behavior_value) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'HOST_HEADER_OVERRIDE') {
      if (!a.host_header && !a.use_domain_origin) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'OVERRIDE_ORIGIN') {
      if (!a.origin) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'REDIRECT') {
      if (!a.redirect_url) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      }
      if (
        !RegExp(
          '^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$',
        ).test(a.redirect_url)
      ) {
        a.validationError = 'Enter a valid URL';
      } else {
        a.validationError = null;
      }
    } else if (a.type === 'BYPASS_CACHE_ON_COOKIE') {
      if (!a.cookie) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      }
      a.validationError = null;
    } else if (a.type === 'CACHE_KEY') {
      let cacheKey = JSON.parse(a.cache_key!);
      if (cacheKey['query_strings']['type'] === 'whitelist') {
        if (!cacheKey['query_strings']['list'] || !cacheKey['query_strings']['list'].length) {
          a.validationError = 'List should include at least one item';
          invalid = true;
          return null;
        }
      }
      a.validationError = null;
    } else if (a.type === 'STREAM_LOGS') {
      if (!a.unified_log_destination) {
        a.validationError = 'This field is required';
        invalid = true;
        return null;
      } else if (
        !a.unified_log_sampling_rate ||
        a.unified_log_sampling_rate < 1 ||
        a.unified_log_sampling_rate > 100
      ) {
        a.validationError = 'Enter percent value';
        invalid = true;
        return null;
      } else {
        a.validationError = null;
      }
    }

    return null;
  });
  return !invalid;
}

export type ConditionField =
  | 'http.request.domain'
  | 'http.request.path'
  | 'http.request.method'
  | 'http.response.status_code';

export type ConditionOperator =
  | 'eq'
  | 'ne'
  | 'lt'
  | 'gt'
  | 'le'
  | 'ge'
  | 'in'
  | 'not_in'
  | 'match'
  | 'regex';

export interface Condition {
  field: ConditionField;
  operator: ConditionOperator;
  value: number | string | string[] | number[] | boolean;
}

export interface ConditionExpression {
  value: Condition[][];
}

export interface Behavior {
  id: string;
  service: string;
  name: string;
  path_pattern: string;
  behavior_actions: BehaviorAction[];
  is_default: boolean;
  complex_condition: ConditionExpression | null;
  additional_paths: string;
}

interface BehaviorState {
  list: Behavior[];
  loading: boolean;
  lastFetch: number | null;
  adding: boolean;
  updating: Behavior | null;
  changeError: string | null;
  invalid: boolean;
  deleted: string[];
  updated: { [key: string]: Behavior };
}

const initialState: BehaviorState = {
  list: [],
  loading: false,
  lastFetch: null,
  adding: false,
  updating: null,
  changeError: null,
  invalid: false,
  deleted: [],
  updated: {},
};

const slice = createSlice({
  name: 'behaviors',
  initialState,
  reducers: {
    // actions => action handlers

    behaviorsReceived: (behaviors, action) => {
      if (behaviors.updating) {
        return;
      }

      behaviors.list = action.payload[0].filter(
        (b: Behavior) => behaviors.deleted.findIndex((d) => d === b.id) === -1,
      );
      behaviors.list = behaviors.list.map((b) => {
        let updated = behaviors.updated[b.id];
        if (updated) {
          return updated;
        }
        return b;
      });

      for (const i in behaviors.list) {
        let actions = behaviors.list[i].behavior_actions;
        for (const i in actions) {
          actions[i].genId = Math.random();
        }
      }
      behaviors.loading = false;
      behaviors.lastFetch = Date.now();
    },

    behaviorsAdded: (behaviors, action) => {
      behaviors.list.push(action.payload[0]);
      behaviors.updating = null;
    },

    behaviorDeleted: (behaviors, action) => {
      const deleted = action.payload[1].payload.id;
      behaviors.list = behaviors.list.filter((s) => {
        return s.id !== deleted;
      });
      behaviors.deleted.push(deleted);
    },

    behaviorUpdated: (behaviors, action) => {
      const index = behaviors.list.findIndex((behavior) => behavior.id === action.payload[0].id);
      behaviors.list[index] = behaviors.updating!;
      behaviors.updated[action.payload[0].id] = behaviors.updating!;
      behaviors.updating = null;
    },

    editUpdatingState: (behaviors, action) => {
      behaviors.updating = action.payload;
      if (behaviors.updating) {
        validateActions(behaviors.updating.behavior_actions);
        behaviors.invalid =
          behaviors.updating &&
          behaviors.updating.behavior_actions.findIndex((a) => a.validationError) !== -1;
        if (behaviors.updating.id) {
          window.location.hash = behaviors.updating.id;
        } else {
          window.location.hash = '';
        }
      }

      behaviors.changeError = '';
    },

    behaviorsChangeStopped: (behaviors, action) => {
      behaviors.adding = false;
      behaviors.updating = null;
      window.location.hash = '';
    },

    behaviorChangeErrorHappened: (behaviors, action) => {
      let msg = action.payload[0];
      const resp = action.payload[1];

      if (resp && resp.data) {
        msg = resp.data[Object.keys(resp.data)[0]];
        // if (msg)
      }

      behaviors.changeError = msg;
    },

    invalidateForm: (behaviors, action) => {
      behaviors.invalid = action.payload;
    },
  },
});

export const {
  behaviorsReceived,
  behaviorsAdded,
  behaviorDeleted,
  behaviorUpdated,
  editUpdatingState,
  behaviorsChangeStopped,
  behaviorChangeErrorHappened,
  invalidateForm,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadBehaviors = (s: string) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/behaviors/`,
    onSuccess: behaviorsReceived.type,
  });

export const addBehavior = (s: string, b: Behavior) =>
  apiCallBegan({
    url: `/api/v1/services/${s}/behaviors/`,
    method: 'post',
    data: b,
    onSuccess: behaviorsAdded.type,
    onError: behaviorChangeErrorHappened.type,
  });

export const deleteBehavior = (s: string, b: string) =>
  apiCallBegan({
    id: b,
    url: `/api/v1/services/${s}/behaviors/${b}/`,
    method: 'delete',
    onSuccess: behaviorDeleted.type,
  });

export const updateBehavior = (s: string, id: string, b: Behavior) =>
  apiCallBegan({
    id: id,
    url: `/api/v1/services/${s}/behaviors/${id}/`,
    method: 'put',
    data: b,
    onSuccess: behaviorUpdated.type,
    onError: behaviorChangeErrorHappened.type,
  });
