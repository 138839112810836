import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../api';
import { mergeDeep } from '../../views/utils';
import {
  adjust,
  adjusted,
  BasicPerValue,
  colors,
  GraphData,
  Metrics,
  Point,
  ServiceStats,
  sumMetrics,
  TrafficResponse,
} from '../model/trafficStatsModels';
import { PayloadAction } from '@reduxjs/toolkit';
import { formatValue } from '../../views/service/serviceDashboard/GraphsUtils';

const xx4Types = [400, 401, 403, 404];
const xx3Types = [301, 302, 304];
const xx5Types = [500, 501, 502, 503, 504];

export function createEmptyGraphData(providers, isPercentage, showSummary = true): GraphData {
  let graphData = new GraphData(providers, isPercentage);

  graphData.showSummary = showSummary;
  if (isPercentage) {
    graphData.max = 100;
  }
  return graphData;
}

function prepareTotalHitsGraphData(
  timestamps,
  points,
  granularity: 'MINUTE' | 'HOUR' | 'DAY',
): GraphData {
  let graphData = new GraphData(['Total Hits'], false);
  graphData.units = 'RPM';

  for (let timestamp of timestamps) {
    let point = points[timestamp];
    if (!point) {
      continue;
    }

    if (!point.basic) {
      continue;
    }

    let basic = point.basic;

    graphData.addPoint(timestamp, basic.hits, 'Total Hits', granularity, basic.numMinutes);
  }
  graphData.granularity = granularity;

  graphData.prepare();

  return graphData;
}

function prepareProviderHitsGraphData(timestamps, providers, points, granularity): GraphData {
  let graphData = new GraphData(providers, false);
  graphData.units = 'RPM';
  for (let provider of providers) {
    for (let timestamp of timestamps) {
      let point = points[timestamp];
      if (!point) {
        continue;
      }

      let basic = point.basic;
      if (!basic) {
        continue;
      }

      let providerBasic = basic[provider];

      if (!providerBasic) {
        continue;
      }

      graphData.addPoint(
        timestamp,
        providerBasic.hits,
        provider,
        granularity,
        providerBasic.numMinutes,
        false,
      );
    }
  }

  graphData.addTimestamps(timestamps);
  graphData.granularity = granularity;

  graphData.prepare();

  return graphData;
}

function prepareGraphData(
  providers,
  timestamps,
  combinedSeries,
  filterStatusCodes,
  filter4xx,
  filter3xx,
  filter5xx,
  filterHttpVersion,
  filterMethod,
  granularity,
  isUTC,
): Object {
  let graphNames = [
    'hits',
    'bytes',
    'cachedHits',
    'cachedBytes',
    'errors',
    'statusCodesHits',
    'statusCodesBytes',
    'edgeCachedHits',
    'edgeCachedBytes',
    'midgressHits',
    'midgressBytes',
    '3xxHits',
    '3xxBytes',
    '4xxHits',
    '4xxBytes',
    '5xxHits',
    '5xxBytes',
    'httpVersionHits',
    'httpVersionBytes',
    'methodHits',
    'methodBytes',
  ];

  let percentageGraphs = {
    httpVersionHits: true,
    httpVersionBytes: true,
    methodHits: true,
    methodBytes: true,
    statusCodesHits: true,
    statusCodesBytes: true,
    cachedHits: true,
    cachedBytes: true,
    edgeCachedHits: true,
    edgeCachedBytes: true,
    errors: true,
    '3xxHits': true,
    '3xxBytes': true,
    '4xxHits': true,
    '4xxBytes': true,
    '5xxHits': true,
    '5xxBytes': true,
  };

  let graphData = {};
  for (const [serviceId, points] of Object.entries(combinedSeries)) {
    let serviceData = graphData[serviceId];
    if (!serviceData) {
      serviceData = {};
      graphData[serviceId] = serviceData;
      for (let graphName of graphNames) {
        serviceData[graphName] = createEmptyGraphData(providers, percentageGraphs[graphName]);
      }
    }

    serviceData['hits'].title = 'Requests';
    serviceData['hits'].legendOnTop = true;
    serviceData['hits'].units = 'RPM';
    serviceData['bytes'].title = 'Bytes';
    serviceData['bytes'].legendOnTop = true;
    serviceData['bytes'].units = 'BPM';
    serviceData['errors'].title = 'Percentage of Errors';
    serviceData['errors'].legendOnTop = true;
    serviceData['errors'].units = '%';
    serviceData['errors'].max = undefined;
    serviceData['cachedHits'].title = 'Cache Offload';
    serviceData['cachedHits'].legendOnTop = true;
    serviceData['cachedHits'].units = '%';
    serviceData['cachedBytes'].title = 'Cache Offload';
    serviceData['cachedBytes'].legendOnTop = true;
    serviceData['cachedBytes'].units = '%';
    serviceData['edgeCachedHits'].title = 'Edge Cache Offload';
    serviceData['edgeCachedHits'].legendOnTop = false;
    serviceData['edgeCachedHits'].units = '%';
    serviceData['edgeCachedBytes'].title = 'Edge Cache Offload';
    serviceData['edgeCachedBytes'].legendOnTop = false;
    serviceData['edgeCachedBytes'].units = '%';
    serviceData['statusCodesHits'].title = 'Status Code';
    serviceData['statusCodesHits'].units = '%';
    serviceData['statusCodesBytes'].title = 'Status Code';
    serviceData['statusCodesBytes'].units = '%';
    serviceData['3xxHits'].title = '3XX Status Code';
    serviceData['3xxHits'].max = undefined;
    serviceData['3xxBytes'].title = '3XX Status Code';
    serviceData['3xxBytes'].max = undefined;
    serviceData['4xxHits'].title = '4XX Status Code';
    serviceData['4xxHits'].max = undefined;
    serviceData['4xxBytes'].title = '4XX Status Code';
    serviceData['4xxBytes'].max = undefined;
    serviceData['5xxBytes'].title = '5XX Status Code';
    serviceData['5xxBytes'].max = undefined;
    serviceData['httpVersionHits'].title = 'HTTP Version';
    serviceData['httpVersionBytes'].title = 'HTTP Version';
    serviceData['methodHits'].title = 'HTTP Method';
    serviceData['methodBytes'].title = 'HTTP Method';

    serviceData['midgressHits'].title = 'Midgress Requests';
    serviceData['midgressHits'].legendOnTop = false;
    serviceData['midgressHits'].units = 'RPM';

    serviceData['midgressBytes'].title = 'Midgress Bytes';
    serviceData['midgressBytes'].legendOnTop = false;
    serviceData['midgressBytes'].units = 'BPM';

    for (let provider of providers) {
      for (let timestamp of timestamps) {
        let point = points[timestamp];
        if (!point) {
          continue;
        }

        let basic = point.basic;
        if (!basic) {
          continue;
        }

        let providerBasic = basic[provider];

        if (!providerBasic) {
          continue;
        }

        serviceData['hits'].addPoint(
          timestamp,
          providerBasic.hits,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['bytes'].addPoint(
          timestamp,
          providerBasic.bytes,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['midgressHits'].addPoint(
          timestamp,
          providerBasic.midgressHits,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['midgressBytes'].addPoint(
          timestamp,
          providerBasic.midgressBytes,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['cachedHits'].addPoint(
          timestamp,
          providerBasic.cachedHitsPercentage,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['cachedBytes'].addPoint(
          timestamp,
          providerBasic.cachedBytesPercentage,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['edgeCachedHits'].addPoint(
          timestamp,
          providerBasic.edgeCachedHitsPercentage,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['edgeCachedBytes'].addPoint(
          timestamp,
          providerBasic.edgeCachedBytesPercentage,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        serviceData['errors'].addPoint(
          timestamp,
          providerBasic.errorsPercentage,
          provider,
          granularity,
          providerBasic.numMinutes,
          false,
        );

        let statusCodes = null;
        if (point.extended?.statusCodes) {
          statusCodes = point.extended?.statusCodes[provider];
        }
        if (statusCodes) {
          let statusCode = statusCodes[filterStatusCodes];
          if (statusCode) {
            let allStatusCodeBytes = 0;
            let allStatusCodeHits = 0;

            for (const [code, metrics] of Object.entries(statusCodes)) {
              let codeParsed = parseInt(code);
              if (codeParsed && codeParsed < 100) {
                allStatusCodeHits += metrics.hits;
                allStatusCodeBytes += metrics.bytes;
              }
            }

            serviceData['statusCodesHits'].addPoint(
              timestamp,
              formatValue(((statusCode.hits * 1.0) / allStatusCodeHits) * 100, false, false)[0],
              provider,
              granularity,
              1,
              false,
            );

            serviceData['statusCodesBytes'].addPoint(
              timestamp,
              formatValue(((statusCode.bytes * 1.0) / allStatusCodeBytes) * 100, false, false)[0],
              provider,
              granularity,
              1,
              false,
            );
          }

          let statusCode3xx = statusCodes[filter3xx];
          if (statusCode3xx) {
            serviceData['3xxHits'].graphLines[provider].push(0);

            serviceData['3xxHits'].addPoint(
              timestamp,
              formatValue(((statusCode3xx.hits * 1.0) / providerBasic.hits) * 100, false, false)[0],
              provider,
              granularity,
              1,
              false,
            );

            serviceData['3xxBytes'].addPoint(
              timestamp,
              formatValue(
                ((statusCode3xx.bytes * 1.0) / providerBasic.bytes) * 100,
                false,
                false,
              )[0],
              provider,
              granularity,
              1,
              false,
            );
          }

          let statusCode4xx = statusCodes[filter4xx];
          if (statusCode4xx) {
            serviceData['4xxHits'].addPoint(
              timestamp,
              formatValue(((statusCode4xx.hits * 1.0) / providerBasic.hits) * 100, false, false)[0],
              provider,
              granularity,
              1,
              true,
            );
            serviceData['4xxBytes'].addPoint(
              timestamp,
              formatValue(
                ((statusCode4xx.bytes * 1.0) / providerBasic.bytes) * 100,
                false,
                false,
              )[0],
              provider,
              granularity,
              1,
              true,
            );
          }

          let statusCode5xx = statusCodes[filter5xx];
          if (statusCode5xx) {
            serviceData['5xxHits'].addPoint(
              timestamp,
              formatValue(((statusCode5xx.hits * 1.0) / providerBasic.hits) * 100, false, false)[0],
              provider,
              granularity,
              1,
              true,
            );
            serviceData['5xxBytes'].addPoint(
              timestamp,
              formatValue(
                ((statusCode5xx.bytes * 1.0) / providerBasic.bytes) * 100,
                false,
                false,
              )[0],
              provider,
              granularity,
              1,
              true,
            );
          }
        }

        if (!point.extended?.httpVersion) {
          continue;
        }

        let httpVersion = point.extended.httpVersion[provider];
        if (!httpVersion) {
          continue;
        }

        let allVersionsHits = 0;
        let allVersionsBytes = 0;

        for (let metrics of Object.values(httpVersion)) {
          allVersionsHits += metrics.hits;
          allVersionsBytes += metrics.bytes;
        }

        let currentVersion = httpVersion[filterHttpVersion];
        if (!currentVersion) {
          continue;
        }

        serviceData.httpVersionHits.addPoint(
          timestamp,
          formatValue(((currentVersion.hits * 1.0) / allVersionsHits) * 100, false, false)[0],
          provider,
          granularity,
          1,
          false,
        );

        serviceData.httpVersionBytes.addPoint(
          timestamp,
          formatValue(((currentVersion.bytes * 1.0) / allVersionsBytes) * 100, false, false)[0],
          provider,
          granularity,
          1,
          false,
        );

        if (!point.extended?.method) {
          continue;
        }

        let method = point.extended.method[provider];
        if (!method) {
          continue;
        }

        let allMethodHits = 0;
        let allMethodBytes = 0;

        for (let metrics of Object.values(method)) {
          allMethodHits += metrics.hits;
          allMethodBytes += metrics.bytes;
        }

        let currentMethod = method[filterMethod];
        if (!currentMethod) {
          continue;
        }

        serviceData.methodHits.addPoint(
          timestamp,
          formatValue(((currentMethod.hits * 1.0) / allMethodHits) * 100, false, false)[0],
          provider,
          granularity,
          1,
          false,
        );

        serviceData.methodBytes.addPoint(
          timestamp,
          formatValue(((currentMethod.bytes * 1.0) / allMethodBytes) * 100, false, false)[0],
          provider,
          granularity,
          1,
          false,
        );
      }
    }
  }

  for (let serviceId of Object.keys(graphData)) {
    for (let graphName of graphNames) {
      let graph = graphData[serviceId][graphName];
      graph.granularity = granularity;
      graph.addTimestamps(timestamps);
      graph.prepare();
      graph.calculateLabels(isUTC);

      let count = 0;
      for (let lineName of graph.lineNames) {
        graph.colors[lineName] = colors[count];
        count++;
      }
    }
  }

  return graphData;
}

function prepareTotalGraphData(timestamps, combinedSeries, granularity, isUTC) {
  let graphNames = [
    'hits',
    'bytes',
    'cachedHits',
    'cachedBytes',
    'edgeCachedHits',
    'edgeCachedBytes',
    'errors',
    'statusCodesHits',
    'statusCodesBytes',
    '3xxHits',
    '3xxBytes',
    '4xxHits',
    '4xxBytes',
    '5xxHits',
    '5xxBytes',
    'httpVersionHits',
    'httpVersionBytes',
    'methodHits',
    'methodBytes',
    'midgressHits',
    'midgressBytes',
  ];

  let graphData = {};

  for (const [serviceId, points] of Object.entries(combinedSeries)) {
    let serviceData = graphData[serviceId];
    if (!serviceData) {
      serviceData = {};
      graphData[serviceId] = serviceData;
      serviceData['hits'] = createEmptyGraphData(['Total Hits'], false, false);
      serviceData['hits'].title = 'Requests';
      serviceData['hits'].units = 'RPM';
      serviceData['bytes'] = createEmptyGraphData(['BPM'], false, false);
      serviceData['bytes'].title = 'Bytes';
      serviceData['bytes'].units = 'BPM';
      serviceData['cachedHits'] = createEmptyGraphData(['Cached Hits'], true, false);
      serviceData['cachedHits'].title = 'Cache Offload';
      serviceData['cachedHits'].units = '%';
      serviceData['cachedBytes'] = createEmptyGraphData(['Cached Bytes'], true, false);
      serviceData['cachedBytes'].title = 'Cache Offload';
      serviceData['cachedBytes'].units = '%';

      serviceData['edgeCachedHits'] = createEmptyGraphData(['Edge Cached Hits'], true, false);
      serviceData['edgeCachedHits'].title = 'Edge Cache Offload';
      serviceData['edgeCachedHits'].units = '%';
      serviceData['edgeCachedBytes'] = createEmptyGraphData(['Edge Cached Bytes'], true, false);
      serviceData['edgeCachedBytes'].title = 'Edge Cache Offload';
      serviceData['edgeCachedBytes'].units = '%';

      serviceData['errors'] = createEmptyGraphData(['Pct Errors'], true, false);
      serviceData['errors'].title = 'Percentage Of Errors';
      serviceData['errors'].max = undefined;
      serviceData['statusCodesHits'] = createEmptyGraphData(
        ['2xx', '4xx', '3xx', '5xx', '1xx'],
        true,
      );
      serviceData['statusCodesHits'].stacked = true;
      serviceData['statusCodesHits'].title = 'Status Code';
      serviceData['statusCodesHits'].sumToHundred = true;
      serviceData['statusCodesBytes'] = createEmptyGraphData(
        ['2xx', '4xx', '3xx', '5xx', '1xx'],
        true,
      );
      serviceData['statusCodesBytes'].stacked = true;
      serviceData['statusCodesBytes'].title = 'Status Code';
      serviceData['statusCodesBytes'].sumToHundred = true;
      serviceData['3xxHits'] = createEmptyGraphData(['301', '302', '304', 'Other'], false);
      serviceData['3xxHits'].title = '3xx Status Codes';
      serviceData['3xxHits'].units = 'RPM';
      serviceData['3xxBytes'] = createEmptyGraphData(['301', '302', '304', 'Other'], false);
      serviceData['3xxBytes'].title = '3xx Status Codes';
      serviceData['3xxBytes'].units = 'BPM';
      serviceData['4xxHits'] = createEmptyGraphData(['400', '401', '403', '404', 'Other'], false);
      serviceData['4xxHits'].title = '4xx Status Codes';
      serviceData['4xxHits'].units = 'Number Of Errors';
      serviceData['4xxBytes'] = createEmptyGraphData(['400', '401', '403', '404', 'Other'], false);
      serviceData['4xxBytes'].title = '4xx Status Codes';
      serviceData['4xxBytes'].units = 'BPM';

      serviceData['5xxHits'] = createEmptyGraphData(
        ['500', '501', '502', '503', '504', 'Other'],
        false,
      );
      serviceData['5xxHits'].title = '5xx Status Codes';
      serviceData['5xxHits'].units = 'Number Of Errors';

      serviceData['5xxBytes'] = createEmptyGraphData(
        ['500', '501', '502', '503', '504', 'Other'],
        false,
      );
      serviceData['5xxBytes'].title = '5xx Status Codes';
      serviceData['5xxBytes'].units = 'BPM';

      serviceData['httpVersionHits'] = createEmptyGraphData(
        ['HTTP/1.1', 'HTTP/2', 'HTTP/3', 'HTTP/1'],
        true,
      );

      serviceData['httpVersionHits'].stacked = true;

      serviceData['httpVersionHits'].title = 'HTTP Version';
      serviceData['httpVersionHits'].sumToHundred = true;

      serviceData['httpVersionBytes'] = createEmptyGraphData(
        ['HTTP/1.1', 'HTTP/2', 'HTTP/3', 'HTTP/1'],
        true,
      );
      serviceData['httpVersionBytes'].title = 'HTTP Version';
      serviceData['httpVersionBytes'].stacked = true;
      serviceData['httpVersionBytes'].sumToHundred = true;

      serviceData['methodHits'] = createEmptyGraphData(
        ['GET', 'HEAD', 'OPTIONS', 'POST', 'PUT', 'PATCH', 'DELETE'],
        true,
      );
      serviceData['methodHits'].title = 'HTTP Methods';
      serviceData['methodHits'].stacked = true;
      serviceData['methodHits'].units = '%';
      serviceData['methodHits'].sumToHundred = true;

      serviceData['methodBytes'] = createEmptyGraphData(
        ['GET', 'HEAD', 'OPTIONS', 'POST', 'PUT', 'PATCH', 'DELETE'],
        true,
      );
      serviceData['methodBytes'].title = 'HTTP Methods';
      serviceData['methodBytes'].units = '%';
      serviceData['methodBytes'].stacked = true;
      serviceData['methodBytes'].sumToHundred = true;

      serviceData['midgressHits'] = createEmptyGraphData(['Midgress Requests'], false, false);
      serviceData['midgressHits'].title = 'Midgress Requests';
      serviceData['midgressHits'].units = 'RPM';
      serviceData['midgressBytes'] = createEmptyGraphData(['Midgress BPM'], false, false);
      serviceData['midgressBytes'].title = 'Midgress Bytes';
      serviceData['midgressBytes'].units = 'BPM';
    }
    let count = 0;
    for (let timestamp of timestamps) {
      let point = points[timestamp];
      if (!point) {
        continue;
      }

      if (!point.basic) {
        continue;
      }

      let basic = point.basic;
      count++;
      serviceData.hits.addPoint(timestamp, basic.hits, 'Total Hits', granularity, 1);
      serviceData.bytes.addPoint(timestamp, basic.bytes, 'BPM', granularity, 1);

      serviceData.midgressHits.addPoint(
        timestamp,
        basic.midgressHits,
        'Midgress Requests',
        granularity,
        1,
      );
      serviceData.midgressBytes.addPoint(
        timestamp,
        basic.midgressBytes,
        'Midgress BPM',
        granularity,
        1,
      );

      serviceData.cachedHits.addPoint(
        timestamp,
        basic.cachedHitsPercentage,
        'Cached Hits',
        granularity,
        basic.numMinutes,
      );

      serviceData.cachedBytes.addPoint(
        timestamp,
        basic.cachedBytesPercentage,
        'Cached Bytes',
        granularity,
        basic.numMinutes,
      );

      serviceData.edgeCachedHits.addPoint(
        timestamp,
        basic.edgeCachedHitsPercentage,
        'Edge Cached Hits',
        granularity,
        basic.numMinutes,
      );

      serviceData.edgeCachedBytes.addPoint(
        timestamp,
        basic.edgeCachedBytesPercentage,
        'Edge Cached Bytes',
        granularity,
        basic.numMinutes,
      );

      serviceData.errors.addPoint(
        timestamp,
        basic.errorsPercentage,
        'Pct Errors',
        granularity,
        basic.numMinutes,
      );

      if (!point.extended) {
        continue;
      }

      let statusCodes = point.extended.statusCodes;
      if (!statusCodes) {
        continue;
      }

      let allStatusCodeBytes = 0;
      let allStatusCodeHits = 0;

      for (const [code, metrics] of Object.entries(statusCodes)) {
        let codeParsed = parseInt(code);
        if (codeParsed && codeParsed < 100) {
          allStatusCodeHits += metrics.hits;
          allStatusCodeBytes += metrics.bytes;
        }
      }
      for (let statusCode of ['1', '2', '3', '4', '5']) {
        let statusCodeMetrics = statusCodes[statusCode];
        let hitsPercentage = 0;
        let bytesPercentage = 0;
        if (statusCodeMetrics) {
          let hits = statusCodeMetrics.hits;
          let bytes = statusCodeMetrics.bytes;
          if (statusCode === '4') {
            hits = adjusted(hits, granularity);
            bytes = adjusted(bytes, granularity);
          }
          hitsPercentage = ((hits * 1.0) / allStatusCodeHits) * 100;
          bytesPercentage = ((bytes * 1.0) / allStatusCodeBytes) * 100;
        }

        serviceData.statusCodesHits.addPoint(
          timestamp,
          formatValue(hitsPercentage, false, false)[0],
          statusCode + 'xx',
          granularity,
          1,
        );

        serviceData.statusCodesBytes.addPoint(
          timestamp,
          formatValue(bytesPercentage, false, false)[0],
          statusCode + 'xx',
          granularity,
          1,
        );
      }

      let xx4Types = [400, 401, 403, 404, 'other_4xx'];

      let xx3Types = [301, 302, 304, 'other_3xx'];

      let xx5Types = [500, 501, 502, 503, 504, 'other_5xx'];

      for (let xx3Code of xx3Types) {
        let codeMetrics = statusCodes[xx3Code + ''];
        if (!codeMetrics) {
          continue;
        }
        if (xx3Code === 'other_3xx') {
          xx3Code = 'Other';
        }
        if (codeMetrics) {
          serviceData['3xxHits'].addPoint(
            timestamp,
            codeMetrics.hits,
            xx3Code + '',
            granularity,
            1,
          );
          serviceData['3xxBytes'].addPoint(
            timestamp,
            codeMetrics.bytes,
            xx3Code + '',
            granularity,
            1,
          );
        }
      }

      for (let xx4Code of xx4Types) {
        let codeMetrics = statusCodes[xx4Code + ''];
        if (xx4Code === 'other_4xx') {
          xx4Code = 'Other';
        }
        if (codeMetrics) {
          serviceData['4xxHits'].addPoint(
            timestamp,
            codeMetrics.hits,
            xx4Code + '',
            granularity,
            1,
            true,
          );
          serviceData['4xxBytes'].addPoint(
            timestamp,
            codeMetrics.bytes,
            xx4Code + '',
            granularity,
            1,
            true,
          );
        }
      }

      for (let xx5Code of xx5Types) {
        let codeMetrics = statusCodes[xx5Code + ''];
        if (xx5Code === 'other_5xx') {
          xx5Code = 'Other';
        }
        if (codeMetrics) {
          serviceData['5xxHits'].addPoint(
            timestamp,
            codeMetrics.hits,
            xx5Code + '',
            granularity,
            1,
            true,
          );
          serviceData['5xxBytes'].addPoint(
            timestamp,
            codeMetrics.bytes,
            xx5Code + '',
            granularity,
            1,
            true,
          );
        }
      }

      if (!point.extended.httpVersion) {
        continue;
      }

      let allVersionHits = 0;
      let allVersionBytes = 0;

      for (const [version, metrics] of Object.entries(point.extended.httpVersion)) {
        if (metrics.adjusted) {
          allVersionHits += metrics.hits;
          allVersionBytes += metrics.bytes;
        } else {
          allVersionHits += adjusted(metrics.hits, granularity);
          allVersionBytes += adjusted(metrics.hits, granularity);
        }
      }

      for (let version of ['HTTP/1.1', 'HTTP/2', 'HTTP/3', 'HTTP/1']) {
        let metrics = point.extended.httpVersion[version];
        let hitsPercentage = 0;
        let bytesPercentage = 0;
        if (metrics) {
          hitsPercentage = ((metrics.hits * 1.0) / allVersionHits) * 100;
          bytesPercentage = ((metrics.bytes * 1.0) / allVersionBytes) * 100;
        }
        serviceData['httpVersionHits'].addPoint(
          timestamp,
          formatValue(hitsPercentage, false, false)[0],
          version,
          granularity,
          1,
        );
        serviceData['httpVersionBytes'].addPoint(
          timestamp,
          formatValue(bytesPercentage, false, false)[0],
          version,
          granularity,
          1,
        );
      }

      let allMethodHits = 0;
      let allMethodBytes = 0;

      for (const [version, metrics] of Object.entries(point.extended.method)) {
        if (metrics.adjusted) {
          allMethodHits += metrics.hits;
          allMethodBytes += metrics.bytes;
        } else {
          allMethodHits += adjusted(metrics.hits, granularity);
          allMethodBytes += adjusted(metrics.hits, granularity);
        }
      }

      for (let method of ['GET', 'HEAD', 'OPTIONS', 'POST', 'PUT', 'DELETE', 'PATCH']) {
        let metrics = point.extended.method[method];
        let hitsPercentage = 0;
        let bytesPercentage = 0;
        if (metrics) {
          hitsPercentage = ((metrics.hits * 1.0) / allMethodHits) * 100;
          bytesPercentage = ((metrics.bytes * 1.0) / allMethodBytes) * 100;
        }
        serviceData['methodHits'].addPoint(
          timestamp,
          formatValue(hitsPercentage, false, false)[0],
          method,
          granularity,
          1,
        );
        serviceData['methodBytes'].addPoint(
          timestamp,
          formatValue(bytesPercentage, false, false)[0],
          method,
          granularity,
          1,
        );
      }
    }
  }

  for (let graphName of graphNames) {
    for (let serviceId of Object.keys(combinedSeries)) {
      let graph: GraphData = graphData[serviceId][graphName];

      graph.granularity = granularity;
      graph.addTimestamps(timestamps);
      graph.prepare();
      graph.calculateLabels(isUTC);

      if (graphName === 'cachedHits' || graphName === 'cachedBytes' || graphName === 'errors') {
        let val = graph.summary[0].percent < 0 ? 0 : graph.summary[0].percent;
        graph.totalStr = Math.round(val * 100) / 100 + '%';
        graph.totalTitle = 'Average';
      }

      let count = 0;
      for (let lineName of graph.lineNames) {
        graph.colors[lineName] = colors[count];
        count++;
        if (count == colors.length) {
          count = 0;
        }
      }
    }
  }

  return graphData;
}
const slice = createSlice({
  name: 'mainStats',
  initialState: {
    servicesTraffic: {},
    startTime: new Date().getTime() - 3600000,
    endTime: new Date().getTime(),
    filterByGeo: null,
    filterByStatusCode: '2',
    filterBy4xx: '404',
    filterBy3xx: '301',
    filterBy5xx: '503',
    filterByHttpVersion: 'HTTP/1.1',
    filterByMethod: 'GET',
    combinedSeries: {},
    totalCombined: {},
    timestamps: [],
    totalTimestamps: [],
    allProviders: [],
    graphsData: {},
    totalGraphsData: {},
    holisticPreviewGraphs: {},
    comparisonPreviewGraphs: {},
    currentGranularity: 'MINUTE',
    isUTC: false,
    incompleteData: {},
    receivedStatusCodes: false,
    receivedBasic: false,
    receivedHTTPVersion: false,
  },
  reducers: {
    trafficReceived: (serviceTraffic, action: PayloadAction<TrafficResponse>) => {
      if (action.payload[1].payload.url.indexOf('status_code') !== -1) {
        serviceTraffic.receivedStatusCodes = true;
      } else if (action.payload[1].payload.url.indexOf('http_version') !== -1) {
        serviceTraffic.receivedHTTPVersion = true;
      } else {
        serviceTraffic.receivedBasic = true;
      }
      let combinedSeries = {};
      if (serviceTraffic.currentGranularity !== action.payload[0].granularity) {
        serviceTraffic.timestamps = [];
      }
      serviceTraffic.currentGranularity = action.payload[0].granularity;
      for (let serviceStats: ServiceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = new Point();
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }

          for (let providerMetrics of point.metrics) {
            adjust(providerMetrics.metrics, action.payload[0].granularity);

            serviceTraffic.allProviders.push(providerMetrics.providerName);

            if (providerMetrics.advancedMetricName === 'status_code') {
              let statusCodes = perTimestamp.extended.statusCodes;
              let statusCode = parseInt(providerMetrics.advancedMetricValue);
              if (!statusCodes[providerMetrics.providerName]) {
                statusCodes[providerMetrics.providerName] = new BasicPerValue();
              }
              statusCodes[providerMetrics.providerName][Math.floor(statusCode / 100) + ''] =
                sumMetrics(
                  statusCodes[providerMetrics.providerName][Math.floor(statusCode / 100) + ''],
                  providerMetrics.metrics,
                );
              if (statusCode >= 400 && statusCode < 500) {
                let found = false;
                for (let xx4 of xx4Types) {
                  if (statusCode === xx4) {
                    statusCodes[providerMetrics.providerName][xx4] = providerMetrics.metrics;
                    found = true;
                  }
                }
                if (!found) {
                  statusCodes[providerMetrics.providerName]['other_4xx'] = providerMetrics.metrics;
                }
              } else if (statusCode >= 300 && statusCode < 400) {
                let found = false;
                for (let xx3 of xx3Types) {
                  if (statusCode === xx3) {
                    statusCodes[providerMetrics.providerName][xx3] = providerMetrics.metrics;
                    found = true;
                  }
                }
                if (!found) {
                  statusCodes[providerMetrics.providerName]['other_3xx'] = providerMetrics.metrics;
                }
              } else if (statusCode >= 500 && statusCode < 600) {
                let found = false;
                for (let xx5 of xx5Types) {
                  if (statusCode === xx5) {
                    statusCodes[providerMetrics.providerName][xx5] = providerMetrics.metrics;
                    found = true;
                  }
                }
                if (!found) {
                  statusCodes[providerMetrics.providerName]['other_5xx'] = providerMetrics.metrics;
                }
              }
            } else if (providerMetrics.advancedMetricName === 'http_version') {
              let httpVersion = perTimestamp.extended.httpVersion;
              if (!httpVersion[providerMetrics.providerName]) {
                httpVersion[providerMetrics.providerName] = new BasicPerValue();
              }
              httpVersion[providerMetrics.providerName][providerMetrics.advancedMetricValue] =
                providerMetrics.metrics;
              serviceTraffic.timestamps.push(timestamp);
            } else if (providerMetrics.advancedMetricName === 'method') {
              let method = perTimestamp.extended.method;
              if (!method[providerMetrics.providerName]) {
                method[providerMetrics.providerName] = new BasicPerValue();
              }
              method[providerMetrics.providerName][providerMetrics.advancedMetricValue] =
                providerMetrics.metrics;
              serviceTraffic.timestamps.push(timestamp);
            } else {
              let basic = perTimestamp.basic;
              basic[providerMetrics.providerName] = providerMetrics.metrics;
              serviceTraffic.timestamps.push(timestamp);
            }
          }
        }
      }

      serviceTraffic.timestamps = Array.from(new Set(serviceTraffic.timestamps));
      serviceTraffic.allProviders = Array.from(new Set(serviceTraffic.allProviders));
      mergeDeep(serviceTraffic.combinedSeries, combinedSeries);
      serviceTraffic.graphsData = prepareGraphData(
        [...serviceTraffic.allProviders],
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterBy5xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.filterByMethod,
        serviceTraffic.currentGranularity,
        serviceTraffic.isUTC,
      );

      //Check if status codes missing and bytes not missing - meaning no logs based stats
      if (serviceTraffic.receivedBasic && serviceTraffic.receivedStatusCodes) {
        let hasData = true;
        for (let serviceID of Object.keys(serviceTraffic.graphsData)) {
          if (serviceTraffic.graphsData[serviceID]) {
            if (serviceTraffic.graphsData[serviceID]['statusCodesHits']) {
              for (const [lineName, values] of Object.entries(
                serviceTraffic.graphsData[serviceID]['statusCodesHits'].valuesPerTimestamp,
              )) {
                if (
                  Object.values(values).length === 0 ||
                  Object.values(values).findIndex((v) => !!v && v > 0) === -1
                ) {
                  if (serviceTraffic.graphsData[serviceID]['bytes']) {
                    let bytesValues =
                      serviceTraffic.graphsData[serviceID]['bytes'].valuesPerTimestamp[lineName];
                    if (
                      Object.values(bytesValues).length > 0 &&
                      Object.values(bytesValues).findIndex((v) => !!v && v > 0) !== -1
                    ) {
                      hasData = false;
                    }
                  }
                }
              }
            }
          }
          serviceTraffic.incompleteData[serviceID] = !hasData;
        }
      }
    },

    holisticReceived: (serviceTraffic, action: PayloadAction<TrafficResponse>) => {
      let timestamps = [];
      let points = {};
      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = points[timestamp];
          if (!perTimestamp) {
            perTimestamp = {
              basic: {},
            };
            points[timestamp] = perTimestamp;
          }

          for (let aggregatedMetrics of point.metrics) {
            adjust(aggregatedMetrics.metrics, action.payload[0].granularity);
            perTimestamp['basic'] = aggregatedMetrics.metrics;
            timestamps.push(timestamp);
          }
        }
        serviceTraffic.holisticPreviewGraphs[serviceID] = prepareTotalHitsGraphData(
          timestamps,
          points,
          action.payload[0].granularity,
        );
      }
    },

    comparisonReceived: (serviceTraffic, action: PayloadAction<TrafficResponse>) => {
      let timestamps = [];
      let providers = [];
      let points = {};
      serviceTraffic.currentGranularity = action.payload[0].granularity;
      for (let serviceStats: ServiceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = points[timestamp];
          if (!perTimestamp) {
            perTimestamp = new Point();
            points[timestamp] = perTimestamp;
          }

          for (let providerMetrics of point.metrics) {
            adjust(providerMetrics.metrics, action.payload[0].granularity);

            providers.push(providerMetrics.providerName);
            let basic = perTimestamp.basic;
            basic[providerMetrics.providerName] = providerMetrics.metrics;
            timestamps.push(timestamp);
          }
        }
        timestamps = Array.from(new Set(timestamps));
        providers = Array.from(new Set(providers));
        serviceTraffic.comparisonPreviewGraphs[serviceID] = prepareProviderHitsGraphData(
          timestamps,
          providers,
          points,
          action.payload[0].granularity,
        );
      }
    },

    totalTrafficReceived: (serviceTotalTraffic, action) => {
      let combinedSeries = {};
      serviceTotalTraffic.currentGranularity = action.payload[0].granularity;
      for (let serviceStats of action.payload[0].serviceStats) {
        let serviceID = serviceStats.serviceID;
        if (!combinedSeries[serviceID]) {
          combinedSeries[serviceID] = {};
        }

        for (let point of serviceStats.points) {
          let timestamp = point.timestamp / 1000;
          let perTimestamp = combinedSeries[serviceID][timestamp];
          if (!perTimestamp) {
            perTimestamp = new Point();
            combinedSeries[serviceID][timestamp] = perTimestamp;
          }

          serviceTotalTraffic.totalTimestamps.push(timestamp);

          for (let aggregatedMetrics of point.metrics) {
            if (aggregatedMetrics.advancedMetricName === 'status_code') {
              let statusCodes = perTimestamp.extended.statusCodes;
              let statusCode = parseInt(aggregatedMetrics.advancedMetricValue);
              if (statusCode < 400) {
                adjust(aggregatedMetrics.metrics, action.payload[0].granularity);
              }
              if (!statusCodes[Math.floor(statusCode / 100) + '']) {
                statusCodes[Math.floor(statusCode / 100) + ''] = new Metrics();
              }
              statusCodes[Math.floor(statusCode / 100) + ''] = sumMetrics(
                statusCodes[Math.floor(statusCode / 100) + ''],
                aggregatedMetrics.metrics,
              );

              if (statusCode >= 400 && statusCode < 500) {
                if (xx4Types.findIndex((x) => x === statusCode) !== -1) {
                  statusCodes[statusCode] = aggregatedMetrics.metrics;
                } else {
                  statusCodes['other_4xx'] = aggregatedMetrics.metrics;
                }
              } else if (statusCode >= 300 && statusCode < 400) {
                if (xx3Types.findIndex((x) => x === statusCode) !== -1) {
                  statusCodes[statusCode] = aggregatedMetrics.metrics;
                } else {
                  statusCodes['other_3xx'] = aggregatedMetrics.metrics;
                }
              } else if (statusCode >= 500 && statusCode < 600) {
                if (xx5Types.findIndex((x) => x === statusCode) !== -1) {
                  statusCodes[statusCode] = aggregatedMetrics.metrics;
                } else {
                  statusCodes['other_5xx'] = aggregatedMetrics.metrics;
                }
              }
            } else if (aggregatedMetrics.advancedMetricName === 'http_version') {
              adjust(aggregatedMetrics.metrics, action.payload[0].granularity);
              let httpVersion = perTimestamp.extended.httpVersion;
              httpVersion[aggregatedMetrics.advancedMetricValue] = aggregatedMetrics.metrics;
            } else if (aggregatedMetrics.advancedMetricName === 'method') {
              adjust(aggregatedMetrics.metrics, action.payload[0].granularity);
              let method = perTimestamp.extended.method;
              method[aggregatedMetrics.advancedMetricValue] = aggregatedMetrics.metrics;
            } else {
              adjust(aggregatedMetrics.metrics, action.payload[0].granularity);
              perTimestamp['basic'] = aggregatedMetrics.metrics;
            }
          }
        }
      }
      serviceTotalTraffic.totalTimestamps = Array.from(
        new Set(serviceTotalTraffic.totalTimestamps),
      );
      mergeDeep(serviceTotalTraffic.totalCombined, combinedSeries);
      serviceTotalTraffic.totalGraphsData = prepareTotalGraphData(
        serviceTotalTraffic.totalTimestamps,
        serviceTotalTraffic.totalCombined,
        serviceTotalTraffic.currentGranularity,
        serviceTotalTraffic.isUTC,
      );
    },

    updateRange: (serviceTraffic, action) => {
      serviceTraffic.graphData = {};
      serviceTraffic.combinedSeries = {};
      serviceTraffic.totalCombined = {};
      serviceTraffic.startTime = Math.floor(action.payload.startTime / 60000) * 60000;
      serviceTraffic.endTime = Math.floor(action.payload.endTime / 60000) * 60000;
      serviceTraffic.isUTC = action.payload.isUTC;
      serviceTraffic.timestamps = [];
      serviceTraffic.totalTimestamps = [];
    },

    onError: (serviceTraffic, action) => {
      let msg = action.payload[0];

      const resp = action.payload[1];

      if (resp && resp.data) {
        if (typeof resp.data === 'string' || resp.data instanceof String) {
          msg = resp.data;
        } else {
          msg = resp.data[Object.keys(resp.data)[0]];
        }
      }

      serviceTraffic.error = msg;
    },

    updateGeo: (serviceTraffic, action) => {
      serviceTraffic.graphData = {};
      serviceTraffic.combinedSeries = {};
      serviceTraffic.totalCombined = {};
      serviceTraffic.filterByGeo = action.payload;
      let hashParams = document.location.hash.split('&');
      document.location.hash = hashParams.filter((param) => param.indexOf('geo=') === -1).join('&');
      if (serviceTraffic.filterByGeo && serviceTraffic.filterByGeo.country) {
        document.location.hash += '&geo=' + serviceTraffic.filterByGeo.country;
      }
    },

    modifyExtendedStatsFinished: (serviceTraffic, action) => {
      serviceTraffic.graphData = {};
      serviceTraffic.combinedSeries = {};
      serviceTraffic.startTime = new Date().getTime() - 3600000;
      serviceTraffic.endTime = new Date().getTime();
    },

    chooseStatusCode: (serviceTraffic, action) => {
      serviceTraffic.filterByStatusCode = action.payload;
      serviceTraffic.graphsData = prepareGraphData(
        [...serviceTraffic.allProviders],
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterBy5xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.filterByMethod,
        serviceTraffic.currentGranularity,
        serviceTraffic.isUTC,
      );
    },

    choose3xx: (serviceTraffic, action) => {
      serviceTraffic.filterBy3xx = action.payload;
      serviceTraffic.graphsData = prepareGraphData(
        [...serviceTraffic.allProviders],
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterBy5xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.filterByMethod,
        serviceTraffic.currentGranularity,
        serviceTraffic.isUTC,
      );
    },

    choose4xx: (serviceTraffic, action) => {
      serviceTraffic.filterBy4xx = action.payload;
      serviceTraffic.graphsData = prepareGraphData(
        [...serviceTraffic.allProviders],
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterBy5xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.filterByMethod,
        serviceTraffic.currentGranularity,
        serviceTraffic.isUTC,
      );
    },

    choose5xx: (serviceTraffic, action) => {
      serviceTraffic.filterBy5xx = action.payload;
      serviceTraffic.graphsData = prepareGraphData(
        [...serviceTraffic.allProviders],
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterBy5xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.filterByMethod,
        serviceTraffic.currentGranularity,
        serviceTraffic.isUTC,
      );
    },

    chooseHTTPVersion: (serviceTraffic, action) => {
      serviceTraffic.filterByHttpVersion = action.payload;
      serviceTraffic.graphsData = prepareGraphData(
        [...serviceTraffic.allProviders],
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterBy5xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.filterByMethod,
        serviceTraffic.currentGranularity,
        serviceTraffic.isUTC,
      );
    },
    chooseMethod: (serviceTraffic, action) => {
      serviceTraffic.filterByMethod = action.payload;
      serviceTraffic.graphsData = prepareGraphData(
        [...serviceTraffic.allProviders],
        serviceTraffic.timestamps,
        serviceTraffic.combinedSeries,
        serviceTraffic.filterByStatusCode,
        serviceTraffic.filterBy4xx,
        serviceTraffic.filterBy3xx,
        serviceTraffic.filterBy5xx,
        serviceTraffic.filterByHttpVersion,
        serviceTraffic.filterByMethod,
        serviceTraffic.currentGranularity,
        serviceTraffic.isUTC,
      );
    },
  },
});

export const {
  trafficReceived,
  updateRange,
  onError,
  updateGeo,
  modifyExtendedStatsFinished,
  choose3xx,
  chooseStatusCode,
  choose4xx,
  choose5xx,
  chooseHTTPVersion,
  holisticReceived,
  comparisonReceived,
  totalTrafficReceived,
  chooseMethod,
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadTraffic = (service, startTime, endTime, geo, advancedMetricName) => {
  let geoParam = '';
  if (geo && (geo.continent || geo.country)) {
    geoParam = '&geo=' + (geo.continent || geo.country);
  }

  let advancedMetricNameParam = '';
  if (advancedMetricName) {
    advancedMetricNameParam = '&advancedMetricName=' + advancedMetricName;
  }
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/` +
      service +
      '?startTime=' +
      startTime +
      '&endTime=' +
      endTime +
      geoParam +
      advancedMetricNameParam,
    onSuccess: trafficReceived.type,
    onError: onError.type,
  });
};

export const loadTotalTraffic = (service, startTime, endTime, geo, advancedMetricName) => {
  let geoParam = '';
  if (geo && (geo.continent || geo.country)) {
    geoParam = '&geo=' + (geo.continent || geo.country);
  }
  let advancedMetricNameParam = '';
  if (advancedMetricName) {
    advancedMetricNameParam = '&advancedMetricName=' + advancedMetricName;
  }

  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/total/` +
      service +
      '?startTime=' +
      startTime +
      '&endTime=' +
      endTime +
      geoParam +
      advancedMetricNameParam,
    onSuccess: totalTrafficReceived.type,
    onError: onError.type,
  });
};

export const loadHolisticPreview = (service) => {
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/total/` +
      service +
      '?startTime=' +
      (new Date().getTime() - 3600000) +
      '&endTime=' +
      new Date().getTime(),
    onSuccess: holisticReceived.type,
    onError: onError.type,
  });
};

export const loadComparisonPreview = (service) => {
  return apiCallBegan({
    url:
      `/api/v2/traffic/overtime/` +
      service +
      '?startTime=' +
      (new Date().getTime() - 3600000) +
      '&endTime=' +
      new Date().getTime(),
    onSuccess: comparisonReceived.type,
    onError: onError.type,
  });
};

export const modifyExtendedStats = (service, enable) => {
  return apiCallBegan({
    url: `/api/v1/services/${service}/modify_extended_stats/`,
    method: 'PUT',
    data: {
      enable: enable,
    },
    onSuccess: modifyExtendedStatsFinished.type,
    onError: onError.type,
  });
};
